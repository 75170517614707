/* App.css */

/* Main wrapper for intro content */
/* Contact Section Styling */
.contact-section {
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px solid #e2e8f0;
    /* Subtle separator line */
    text-align: center;
}

.contact-section h2 {
    font-size: 1.8em;
    color: #2d3748;
    margin-bottom: 10px;
}

.contact-section p {
    font-size: 1.1em;
    color: #4a5568;
    margin-bottom: 20px;
    line-height: 1.6;
}

/* Contact Links */
.contact-links {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.contact-link {
    font-size: 1em;
    font-weight: bold;
    color: #3182ce;
    text-decoration: none;
    transition: color 0.3s ease, transform 0.3s ease;
}

.contact-link:hover {
    color: #2b6cb0;
    transform: translateY(-2px);
    /* Slight lift effect */
}

/* Main wrapper for intro content */
.intro-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    /* Full viewport height */
    padding: 20px;
    text-align: center;
    background-color: #f4f6f8;
    animation: fadeIn 0.7s ease-in-out forwards;
    box-sizing: border-box;
    /* Include padding within height */
}
/* Headline title styling */
.intro-title {
    font-size: 2.8em;
    font-weight: 700;
    color: #2d3748;
    /* Dark, professional text color */
    margin-bottom: 20px;
    letter-spacing: 0.5px;
}

/* Intro description text */
.intro-description {
    font-size: 1.2em;
    color: #4a5568;
    /* Slightly lighter text color for contrast */
    margin-bottom: 25px;
    line-height: 1.6;
    max-width: 600px;
}

/* Body text styling */
.intro-body {
    font-size: 1em;
    color: #4a5568;
    line-height: 1.6;
    max-width: 500px;
    margin-bottom: 30px;
}

/* Button styling */
.explore-button {
    padding: 14px 30px;
    font-size: 1.1em;
    font-weight: bold;
    color: #ffffff;
    background-color: #3182ce;
    /* Blue color for a professional feel */
    border: none;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    transition: background-color 0.3s ease, transform 0.3s ease;
}

/* Hover and focus state for button */
.explore-button:hover {
    background-color: #2b6cb0;
    transform: translateY(-2px);
    /* Slight lift effect */
}

.explore-button:active {
    transform: translateY(0);
    /* Return to normal on click */
}

/* Fade-in animation */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* App.css */

.main-content-wrapper {
    max-width: 800px;
    margin: 20px auto;
    padding: 40px;
    text-align: center;
    background-color: #f4f6f8;
    border-radius: 12px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    animation: fadeInContent 0.7s ease-in-out forwards;
    opacity: 0;
    /* Start hidden for fade-in effect */
}

/* Main title styling */
.main-title {
    font-size: 2.5em;
    font-weight: 700;
    color: #2d3748;
    margin-bottom: 15px;
    letter-spacing: 0.5px;
}

/* Main description styling */
.main-description {
    font-size: 1.15em;
    color: #4a5568;
    line-height: 1.6;
    margin-bottom: 30px;
}

/* Project title styling */
.project-title {
    font-size: 2em;
    font-weight: 600;
    color: #2d3748;
    margin-top: 30px;
    margin-bottom: 10px;
}

/* Project description styling */
.project-description {
    font-size: 1.1em;
    color: #4a5568;
    line-height: 1.6;
    margin-bottom: 20px;
}

/* Centering container for video and button */
.project-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    /* Space between video and button */
}

/* Embed iframe styling */
.project-video {
    width: 100%;
    max-width: 560px;
    height: 315px;
    border-radius: 8px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
}

/* Link/button styling */
.project-link {
    padding: 12px 25px;
    font-size: 1em;
    font-weight: bold;
    color: #ffffff;
    background-color: #3182ce;
    border: none;
    border-radius: 8px;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
    cursor: pointer;
}

.project-link:hover {
    background-color: #2b6cb0;
    transform: translateY(-2px);
    /* Slight lift effect */
}

/* Fade-in animation */
@keyframes fadeInContent {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}